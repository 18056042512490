<template>
  <div id="app">
    <quiz />
  </div>
</template>

<script>
import Quiz from "./components/Quiz.vue";
export default {
  name: "App",
  components: { Quiz },
};
</script>

<style lang="postcss">
h1 {
}
</style>
